<template>
  <section>
    <div class="text-center mb-2">
      <v-form ref="form" v-model="valid" lazy-validation class="d-flex flex-column" @submit.prevent="validate">
        <v-text-field 
          class="w-100 border" 
          solo 
          flat 
          :label="$t('Search...')" 
          prepend-inner-icon="mdi-magnify"
          v-model="searchText" 
          @keyup.enter="validate" 
          hide-details 
          :rules="[(v) => !!v]" 
          required>
        </v-text-field>
      </v-form>
      
    </div>
    
    <div v-if="!followersError">
      <v-card class="d-flex align-items-center py-2 my-4 border" flat v-for="(item, index) in items" :key="index">
        <v-avatar size="96" rounded class="ms-3">
          <v-img
            :lazy-src="require('@/assets/images/placeholder.png')"
            :src="
              item.profilePicture != null
                ? item.profilePicture
                : require('@/assets/images/profile.jpg')
            "
          ></v-img>
        </v-avatar>
        <span class="f16 font-weight-bold ms-1"
          >{{ item.firstName }} {{ item.lastName }}</span
        >
      </v-card>
    </div>

    <div v-else class="my-4">
          <span>{{ followersErrorText }}</span>
        </div>
    
    <infinite-loading
        v-if="!this.searchFlag"
        @infinite="infiniteHandler"
      >
        <div slot="spinner">
          <!-- {{ $t("Loading...") }} -->
          <v-icon>mdi-selection-ellipse spin</v-icon>
        </div>
        <div slot="no-more">
          <!-- {{ $t("No more items") }} -->
          <v-icon>mdi-access-point-off</v-icon>
        </div>
        <div slot="no-results">
          <!-- {{ $t("No results found") }} -->
          <v-icon>mdi-access-point-off</v-icon>
        </div>
      </infinite-loading>
  </section>
</template>
<script>
import ApiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();
export default {
  data() {
    return {
      searchFlag: false,
      
      searchText: null,
      submit_loading: false,
      valid: false,

      followersError: false,
      followersErrorText: "",

      items: [],
      page: 1,
      size: 12,
    };
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.submitForm();
      }
    },
    submitForm() {
      this.searchFlag = true;
      this.submit_loading = true;
      ApiService.querySearchFollowers(this.searchText)
      .then((response) => {
        this.submit_loading = false;
        this.items = response.data;
      })
      .catch((err) => {
        this.submit_loading = false;
        this.$swal({
          icon: "warning",
          text: err ? err.response.data.detail : this.$t("Error"),
          confirmButtonText: this.$t('OK'),
        });
      });
    },
    infiniteHandler($state) {
      ApiService.getAllFollowers(this.page, this.size)
      .then((response) => {
        if (response.data.content.length) {
          response.data.content.forEach((element) => {
            this.items.push(element);
          });

          this.page += 1;
          $state.loaded();
        } else {
          $state.complete();
        }
      })
      .catch((err) => {
        console.log(err);
        $state.error();
      });
  },
    getAllFollowers(page, size) {
      ApiService.getAllFollowers(page, size)
        .then((response) => {
          if (response && response.data && response.data.content && response.data.content.length) {
            this.followersError = false;
            this.items = response.data.content;
          }
          else {
            this.followersError = true;
            this.followersErrorText = this.$t('You have no followers. The client must follow you to submit the exam');
            
          }
        })
        .catch(() => {
          this.followersError = true;
          this.followersErrorText = this.$t('You have no followers. The client must follow you to submit the exam');
          
        });
    },
  },
  watch: {
    searchText: function () {
      this.page = 1;
      if (this.searchText == null || this.searchText == "") {
        this.getAllFollowers(0, this.size);
      }
    },
  },
  mounted() {
    this.getAllFollowers(0, this.size);
  },
};
</script>